import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const AuthenticationButtons = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Link href="/login">
        <a className="button-outline md:hidden">{t`login`}</a>
      </Link>
      <Link href="/login">
        <a className="button-text hidden md:inline">{t`login`}</a>
      </Link>
      <Link href="/register">
        <a className="button hidden md:inline">{t`register`}</a>
      </Link>
    </>
  );
};

export default AuthenticationButtons;
